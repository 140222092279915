import React,{useEffect,useState} from "react";
import personal_loan_app from "../../assets/images/hero-image1.png";
import { Link } from "react-router-dom";

function HeroSection(props) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Update screen width on resize
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Clean up on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="hero-section">
      <div className="content a">
        <h1 className="hero-content" 
          style={{
            background: "linear-gradient(180deg, #1b9bdc, rgb(117.94, 208.84, 255) 54.51%, #1b9bdc)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            fontSize: screenWidth < 768 ? '21px' : "50px",
            fontWeight: 800,
            marginBottom: "1rem",
            lineHeight: "normal",
            fontFamily: "Poppins",
            textAlign:'justify',
            width: screenWidth < 768 && '300px',
            marginTop: screenWidth < 768 && '50px'
          }}     
        >
          Your Trusted Loan Mitra
          <br />
          Loans Made Easy & Friendly
        </h1>
        <p>
          At CreditMitra, we understand that life doesn't always wait for
          payday. That's why we're here to offer you instant personal loans with
          no hassle and no waiting.
        </p>
        <div className="hero-content-button">
          {/* apply now was directing to samcint, now it's redirecting to same page */}
          <Link to="https://creditmitra.cloudbankin.com/onboard/#/login">
            <buttton className="btn brand-primary py-3 px-4 home-apply">Apply Now</buttton>
          </Link>
          <buttton className="btn brand-secondary py-3 px-4 home-download">
            Download App
          </buttton>
        </div>
      </div>
      <img
        src={personal_loan_app}
        className="img-home"
        alt="personal_loan_app"
        // loading="lazy"
        title="personal_loan_app"
      />
    </div>
  );
}

export default HeroSection;
