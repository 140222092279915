import React, { useEffect, useState } from "react";
import "./contactusbanner.css";

const FixedBanner = () => {
  const [visible, setVisible] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize)
    };
  }, []);

  return (
    <div className={`fixed-banner ${visible ? "visible" : ""}`}>
      <div>
        <h5
          className="bannerText"
          style={{
            color: "#1b9bdc",
            textAlign: "center",
            marginTop: "12px",
            fontSize: "32px",
            fontWeight: 500,
            marginBottom: "16px",
            fontFamily: "Poppins",
            lineHeight: "1.2",
            margin: screenWidth < 768 && 'auto'
          }}
        >
          Get Personal Loan Upto <strong>₹2 Lakhs in 5 Minutes</strong>
          <a
            className="btn btn-primary banner-button"
            href="https://creditmitra.cloudbankin.com/onboard/#/login"
          >
            Apply Now
          </a>
        </h5>
      </div>
    </div>
  );
};

export default FixedBanner;
